<template>
	<div>
		<div class="new_load">加载中，请稍后</div>
		<van-loading v-if="load" class="load_van" size="38px" vertical>加载中...</van-loading>
	</div>
</template>

<script>

import {login} from '@/api/test';
import {Toast} from "_vant@2.12.53@vant";
import {gotoEnterPath} from "../../utils/base";
import env from '../../../env';

export default {
	data() {
		return {
			load:false
		}
	},
	mounted(){

    // 有code表示企微回调
    let href = window.location.href;
    console.log(href, 'href');
    if (href.indexOf('code=') !== -1) {
      let code_list = href.split('?')
      let code = code_list[1].split('&')
      let new_code = code[0].split('=')
      console.log(code_list, code, new_code);

      // code已经使用过，删除code使用标识，重新获取code
      // 否则就用code登录
      if (localStorage.getItem('code_used_'+new_code[1])) {
        localStorage.removeItem('code_used_'+new_code[1]);
      } else {
        this.login(new_code[1])
        return;
      }
    }

    // 没有code则构造企微静默登录
    console.log('no code');
    let online_url = env.online_url + '/#/user/login';
    let redirect_uri = encodeURIComponent(online_url)
    let authorUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + env.corpid + '&redirect_uri='
        + redirect_uri + '&response_type=code&scope=snsapi_base&agentid=' + env.agentId + '#wechat_redirect'
    window.location.href = authorUrl;

	},
	methods: {
    async login(code){
      await login({
        code:code,
        type: env.type,
        corpid: env.corpid,
        agentId: env.agentId,
      }).then((res) =>{

        console.log('login', res);
        if(res.code == 200){

          // 设置token
          localStorage.setItem("token", res.data.token);
          console.log(localStorage.getItem("token"), 'logined');

          // // 跳到首页
          // this.$router.push({
          //   path: `/`
          // })

          // this.$router.go(-1);
          gotoEnterPath(this.$router);

        }else{
          Toast.fail(res.message)
        }

        // 标识code已使用，要重新获取
        localStorage.setItem('code_used_'+code, 1);
      })
    }
	}
}
</script>

<style>

</style>
